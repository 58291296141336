import React from "react";
import { motion } from "framer-motion";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectArticle } from "../../features/articleSlice";

function Post({ title, des, image }) {

	const dispatch = useDispatch();
    const navigate = useNavigate()

	const reduxNews =()=>{
		dispatch(
			selectArticle({
				title, des, image
		   })
		   
		)

		navigate('/viewpost');
		   
	}

	return (
		<>
			<motion.div
				onClick={reduxNews}
				initial={{ y: -100, opacity: 0 }}
				transition={{ duration: 1.2 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				className="flex flex-col bg-green-400/40 items-center justify-center self-center  rounded-md  shadow-md hover:shadow-sky-300 transition-all hover:scale-105 duration-300 ease-in-out min-w-[350px] mt-6 border border-sky-300 hover:border-0 flex-shrink-0 overflow-hidden">
				<div className="bg-white">
					<img
						src={image}
						alt=""
						className=" object-cover p-8 flex-shrink-0 overflow-hidden"
					/>
				</div>
				<div className=" flex flex-nowrap w-full justify-center text-white font-semibold text-lg bg-emerald-500 py-1 flex-shrink-0">
					{title}
				</div>
				<div className="bg-white text-lg font-thin text-slate-950 p-3 flex-shrink-0 min-h-[200px] overflow-hidden text-justify">
					{des}
				</div>
				<div className="my-4 px-8 py-2 cursor-pointer text-slate-950 text-lg bg-white hover:bg-slate-100 rounded-md">
				See More
				</div>
			</motion.div>
		</>
	);
}

export default Post;
