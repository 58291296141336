const NewsData = [
	{
		id: 1,
		title: "Exponential development",
		image: "/geneve.jpeg",
		des: "On ledger Off ledger funds : difference between myth and reality. August 19, 2024 in Geneva and Singapore ",
	},
	{
		id: 2,
		title: "Exponential development",
		image: "/beijing.jpg",
		des: "Cryptography, the means of mobilizing assets by banks. August 19, 2024 in Beijing",
	},
	{
		id: 3,
		title: "Exponential development",
		image: "/hong.jpg",
		des: "Cryptography: cryptocurrency and alternative financing of international trade. August 19, 2024 in Beijing and Hong Kong.",
	},
	{
		id: 4,
		title: "PAPSS for transborder payment",
		image: "/pass.jpg",
		des: "AFRICA55DURABLE's partners designed PAPSS for the benefit of Africa transborder payment",
	},
	{
		id: 5,
		title: "Kenya Gold Miners in Beijing",
		image: "/beijing.jpg",
		des: "Kenya Gold Miners confirmed situated in my Beijing Compound",
	},
	{
		id: 6,
		title: "Maître Daniel Rochefort and Associate",
		image: "/montreal.jpeg",
		des: "Africa55Durable is pleased to inform that its legal staff has been strengthened by the Maître Daniel Rochefort and Associates office as its legal representative.",
	},
	{
		id: 7,
		title: "Contact Gold buyers in Beinjin",
		image: "/beijing.jpg",
		des: "We are leading to one who is rewarding with *cash incentives* for foreign gold miners to registered sub-company in Beijing, and will buy their gold in full on day LBMA price ( no need discount!!)",
	},
];
const TrainingData = [
	{
		id:1,
		refNumb:"C01SG",
		type:"Conference on Exponential Development",
		theme: "On ledger Off ledger funds : difference between myth and reality",
		droitInsc:"12 000 Euros",
		date: "August 19th 2024",
		lieu:"Geneva / Singapour ",
		dateLimite:"August 1st 2024",
		animateur:"funds owners ",
		des:"",
		image: "/logo.png",
		title:"",
	},
	{
		id:2,
		refNumb:"C02SB",
		type:"Conference on Exponential Development",
		theme: "Cryptography: IBO, the means of asset mobilization by banks and the safety and security of cryptographic assets",
		droitInsc:"4 000 Euros ",
		date: "August 19th 2024",
		lieu:"Beijing",
		dateLimite:"August 1st 2024",
		animateur:"Cryptography experts and asset owners",
		des:"",
		image: "/logo.png",
		title:"",
	},
	{
		id:3,
		refNumb:"C03PH",
		type:"Workshop on alternative exponential development",
		theme: "Cryptography: cryptocurrency and alternative financing of international trade. Case of disbursement of 30 million USDT ",
		droitInsc:"4 000 Euros ",
		date: "August 19th 2014",
		lieu:"Pekin / Hong Kong ",
		dateLimite:"August 1st 2024",
		animateur:"Experts in cryptography and asset owners with the participation of beneficiaries of loans granted ",
		des:"",
		image: "/logo.png",
		title:"",
	},
];
const OpportunityData = [
	
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"AFRICA55DURABLE's support to new scheme for any foreign company wishing to establish in China",
		image: "/logo.png",
		title:"Do open a sub company in China",
	},
	{
		id:2,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Thus AFRICA55DURABLE will raise funds for you to develop your company free of charge. Since any legit funds raising being a paid service. To do so AFRICA55DURABLE'S partners will pay for fee registration upon receipt upon receipt of the power of attorney letter to us.",
		image: "/logo.png",
		title:"Fund raising",
	},
];
const HonerData = [
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Born into a pious family and having a father who was a catechist, he followed a Catholic school education until he obtained his Baccalaureate. Laurent Kilachu Bado is considered an intellectual, politician and, on certain occasions, a humorist. It is this combination of profiles that gave him his large audience. His speeches are broadcast and rebroadcast, particularly by young Burkinabé people, via their mobile phones. His speeches since 1987, as controversial as they are, are representative of an opposition that is not very restrictive for President Blaise Compaoré. On the contrary, the latter knew how to use his detractors to serve his legitimacy. The profile of the opponent Bado is representative of the Compaoré era.",
		image: "/bado.png",
		title:"Professor Laurent BADO, the man of two centuries",
	},
	{
		id:2,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Ousmane Sonko, born July 15, 1974 in Thiès, is a Senegalese politician, Prime Minister since April 2, 2024. Founder in 2014 of the African Patriots of Senegal party for work, ethics and fraternity (PASTEF). A senior executive in the Senegalese administration, Ousmane SONKO is an unparalleled pure endogenous African fruit. He pays the price for his rigor and his total rejection of corruption and bribes. The people are convinced of this and vote for his party even though he was unjustly incarcerated and he had the greatness of spirit with his people to designate another wise and worthy African to replace him and carry the torch of leadership. PASTEF in the presidential elections. Sonko calls to support the candidacy of his work colleague and friend Bassirou Diomaye Faye who will be elected President of the Republic with 54% of the votes in the first round. The dignified, humble and valiant Sage djambare Ousmane Sonko has been the Prime Minister of the Republic of Senegal since Tuesday April 2, 2024.",
		image: "/sonko.png",
		title:"Ousmane SONKO, The champion of the fight against corruption in Senegal",
	},
	{
		id:3,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Discreet, he nevertheless has years of mediation and influence behind him. Since April 2021, he has taken the helm of Cosim in Ivory Coast with the heavy mission of countering the spread of the ideology of terrorist groups. His outspokenness is public knowledge. One episode left its mark. On June 21, 2017, all the country's Muslim political figures and businessmen gathered at the large mosque in the upscale Riviera Golf district. It’s the busy days for this Night of Destiny, an important moment in the month of Ramadan. Ousmane Diakité, then executive secretary of the Superior Council of Imams of Côte d'Ivoire (Cosim), speaks. His preaching focuses on illicit enrichment and corruption. “If you embezzle funds, do not think that you have only disobeyed Republic law. You also disobeyed Allah’s orders,” he explains in a calm voice emerging from his graying beard and imposing build. Some in the audience nod their heads in agreement. Manipulation of public markets, abuse of office... No one in particular is targeted, but the imam has harsh words. He insists: “Some people buy a conscience by telling themselves that they will use part of the money they steal to build mosques, to send people to Mecca… The Muslim religion does not accept this . There is no spiritual money laundering. Making sacrifices with stolen money does not make it lawful.",
		image: "/ousmane.png",
		title:"Ousmane Diakité, the imam of the happy medium",
	},
	{
		id:4,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Duncan CAMERON : « For the past 20 odd years I have had a long association with Africa, through Rotary International in its quest to eradicate polio. It is sad to hear that there have been a number of cases announced this past week in Nigeria, due to a group in northern Nigeria who will not allow access to vaccinate. Most of the 40 companies Cameron Group International does business with or for, GIVE BACK 15% TO AS HIGH AS 90% OF THEIR NET PROFITS TO THE COMMUNITIES WE DO BUSINESS WITH. CGI HIRES A MINIMUM OF 80% FROM THE COUNTRY WE DO BUSINESS IN. CLICK ON WORKING WITH PAGE  and see the list of companies we work with.Officially CGI (Africa) LLC, was registered in Accra, Ghana on May 26th 2016. In keeping with our mandate CGI (Africa) is held by Africans, and like all new kids on the block, we have had growing pains, adjusting to the CGI values and earning the right to do business in Africa  »",
		image: "/duncan.png",
		title:"Duncan CAMERON, the Afro-Optimistic",
	},
	
];
const AchievementgData = [
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "dater",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"portion in build",
		image: "/logo.png",
		title:"very soon",
	},
];

export { NewsData, TrainingData, HonerData, AchievementgData, OpportunityData };
