import React, { useState } from "react";
import { Link } from "react-router-dom";

function Welcome() {
	const [eng, setEng] = useState(true);
	const [fren, setFren] = useState(false);

	const engAct = () => {
		setFren(false);
		setEng(true);
	};

	const frenAct = () => {
		setFren(true);
		setEng(false);
	};

	return (
		<>
			{eng && (
				<div className="bg-slate-50 min-h-screen text-slate-950 justify-between items-center flex flex-col">
					<div className="flex flex-col justify-center">
						<div className="flex items-center space-x-5 mt-3">
							<p
								onClick={frenAct}
								className="flex flex-col items-center space-y-4">
								<img
									src="/franc.png"
									alt="Français"
									className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
								/>
							</p>
							<p onClick={engAct} className="flex space-x-4">
								<img
									src="/eng.png"
									alt="English"
									className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
								/>
							</p>
						</div>

						<div className="flex w-full my-6">
							<img
								src="/logo.png"
								alt=""
								className="inline-block h-[114px] w-[120px] object-fill rounded-md mx-auto"
							/>
						</div>
						<div className="flex w-full justify-center text-xl sm:text-4xl font-light my-4">
							Welcome to AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-sm sm:text-3xl font-thin text-center px-3">
							Knock on our door we will open to you, Ask, we will serve you
							majestically AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-3 sm:text-2xl font-thin">
							The Circle of best entities of exponential growth and development
							in the world
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-4 sm:text-xl font-thin">
							AFRICA55DURABLE : EXPONENTIAL GROWTH & DEVELOPMENT
						</div>
						<div className="flex w-full justify-center mb-4 text-lg text-center px-3 sm:text-2xl font-thin">
							Contact: secretary.general@africa55durable.com
						</div>
						<div className="flex w-full justify-center my-6">
							<Link to="/welcome">
								<div className="text-xl bg-teal-800 drop-shadow-md shadow-md shadow-green-300  text-white hover:bg-teal-600 px-4 py-2 rounded-full">
									{" "}
									Enter here for more details
								</div>
							</Link>
						</div>
					</div>
					<div className="flex justify-center w-full text-xl py-2 font-thin bg-green-100">
						© 2024 AFRICA55DURABLE. All rights reserved
					</div>
				</div>
			)}

			{fren && (
				<div className="bg-slate-50 min-h-screen text-slate-950 justify-between items-center flex flex-col">
					<div className="flex flex-col justify-center">
						<div className="flex items-center space-x-5 mt-3">
							<p className="flex flex-col items-center space-y-4">
								<img
									onClick={frenAct}
									src="/franc.png"
									alt="Français"
									className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
								/>
							</p>
							<p className="flex space-x-4">
								<img
									onClick={engAct}
									src="/eng.png"
									alt="English"
									className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
								/>
							</p>
						</div>

						<div className="flex w-full my-6">
							<img
								src="/logo.png"
								alt=""
								className="inline-block h-[114px] w-[120px] object-fill rounded-md mx-auto"
							/>
						</div>
						<div className="flex w-full justify-center text-xl sm:text-4xl font-light  my-4">
							Bienvenue à AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-sm sm:text-2xl font-thin text-center px-3">
							Frappez à notre porte et nous vous ouvrirons, Demandez et nous
							vous servirons majestueusement AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-3 sm:text-2xl font-thin">
							Le Cercle des meilleures entités de croissance et de développement
							exponentiel au monde
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-4 sm:text-xl font-thin">
							AFRICA55DURABLE : CROISSANCE & DÉVELOPPEMENT EXPONENTIELS
						</div>
						<div className="flex w-full justify-center mb-4 text-lg text-center px-3 sm:text-2xl font-thin">
							Contact: secretary.general@africa55durable.com
						</div>
						<div className="flex w-full justify-center my-6">
							<Link to="/bienvenue">
								<div className="text-xl bg-teal-800 drop-shadow-md shadow-md shadow-green-300  text-white hover:bg-teal-600 px-4 py-2 rounded-full">
									{" "}
									Pour en savoir plus
								</div>
							</Link>
						</div>
					</div>
					<div className="flex justify-center w-full text-xl py-2 font-thin bg-green-100">
						© 2024 AFRICA55DURABLE. All rights reserved
					</div>
				</div>
			)}
		</>
	);
}

export default Welcome;
