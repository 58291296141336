import React from "react";
import LayoutFr from "../../components/LayoutFr";
import HeroFr from "./HeroFr";
import IntroFr from "./IntroFr";
import MywhyFr from "./MywhyFr";


function AccueilFr() {
	
	return (
		<LayoutFr>
			<HeroFr />
			<IntroFr />
			<MywhyFr />
		</LayoutFr>
	);
}

export default AccueilFr;
