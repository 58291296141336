import React from "react";
import Post from "./Post";
import { NewsData } from "../../data/data";


function Posts() {
	return (
		<>
			<div className="sm:flex w-full overflow-x-auto  scrollbar-hide space-x-4 px-3">
				{NewsData &&
					NewsData.map((data, id) => (
						<div key={id}>
							<Post title={data.title} image={data?.image} des={data?.des} />
						</div>
					))}
			</div>
		</>
	);
}

export default Posts;
